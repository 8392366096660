import React from 'react'
import "./Resume.css"

function Resume() {
  return (
    <div className='resume'>
      
      <img className='resume__image' src={require("../../assets/Abner_Pena_Resume.png")} alt="" />
    </div>
  )
}

export default Resume
